import { default as indexiNIqId617aMeta } from "/app/apps/payment-status/pages/[id]/index.vue?macro=true";
import { default as index7m9UMjwB71Meta } from "/app/apps/payment-status/pages/index.vue?macro=true";
export default [
  {
    name: "id",
    path: "/:id()",
    component: () => import("/app/apps/payment-status/pages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/apps/payment-status/pages/index.vue").then(m => m.default || m)
  }
]