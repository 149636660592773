import {defineRule} from 'vee-validate';

export default defineNuxtPlugin(() => {
    defineRule('required', value => {
        if (typeof value === 'number') {
            return true;
        }

        if (!value || !value.length) {
            return 'This field is required';
        }
        return true;
    });
});