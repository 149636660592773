import revive_payload_client_veQK773nwc from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_lightningcss@_66cyvdezjd624a5acxox64npp4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EzHSwROBNe from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_lightningcss@_66cyvdezjd624a5acxox64npp4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9aqj1gJ8ZL from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_lightningcss@_66cyvdezjd624a5acxox64npp4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ZddoxbrWqk from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_lightningcss@_66cyvdezjd624a5acxox64npp4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vzBwSBPVYw from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_lightningcss@_66cyvdezjd624a5acxox64npp4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_6xzU7OArq1 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_lightningcss@_66cyvdezjd624a5acxox64npp4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/payment-status/.nuxt/components.plugin.mjs";
import prefetch_client_A2nArG9lwA from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_lightningcss@_66cyvdezjd624a5acxox64npp4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_yUVfXiEL6Z from "/app/node_modules/.pnpm/@sentry+nuxt@8.25.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.25.1_@opentelemetry+api@1._t7jwmc4ptb6swxmusdtrb5tyte/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/app/apps/payment-status/.nuxt/sentry-client-config.mjs";
import tolgee_wBqASM3441 from "/app/apps/payment-status/plugins/tolgee.ts";
import vee_validate_KcKlKmvCrJ from "/app/apps/payment-status/plugins/vee-validate.ts";
export default [
  revive_payload_client_veQK773nwc,
  unhead_EzHSwROBNe,
  router_9aqj1gJ8ZL,
  payload_client_ZddoxbrWqk,
  navigation_repaint_client_vzBwSBPVYw,
  chunk_reload_client_6xzU7OArq1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_A2nArG9lwA,
  sentry_client_yUVfXiEL6Z,
  sentry_client_config_o34nk2sJbg,
  tolgee_wBqASM3441,
  vee_validate_KcKlKmvCrJ
]