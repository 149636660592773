import {Tolgee, DevTools, VueTolgee, FormatSimple} from '@tolgee/vue';
import localeEn from '~/assets/i18n/en.json';
import localeTr from '~/assets/i18n/tr-TR.json';

export default defineNuxtPlugin((nuxtApp) => {
    const vueApp = nuxtApp.vueApp;

    const tolgee = Tolgee()
        .use(DevTools())
        .use(FormatSimple())
        .init({
            language: 'en',
            // for production
            staticData: {
                en: localeEn,
                'tr-TR': localeTr
            }
        });

    vueApp.use(VueTolgee, {
        tolgee
    });
});